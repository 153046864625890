.pulse {
    animation: pulse 20s infinite;
}

@keyframes pulse {
    0% {
        transform: scale(1);
    }
    3% {
        transform: scale(1.3);
    }
    6% {
        transform: scale(1);
    }
}