/* .App {
  text-align: center;
} */

/* .App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

body {
  background-color: #003153;
}

@property --caption-text-color {
  syntax: "<color>";
  inherits: false;
  initial-value: #FFFFFF;
}

@property --caption-background-color {
  syntax: "<color>";
  inherits: false;
  initial-value: #000000;
}

.caption-box {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  margin-bottom: calc(5 * (0.5em - 0.5ex) + 2 * 0.05em);
  width: min(23em, calc(100vw - 8 * (0.5em - 0.5ex) - 4 * 0.05em));
  min-height: 2.8em;
  font-size: 4.25vh;
  word-spacing: 0.075em;
  line-height: 1.4em;
  font-family: 'Martel', serif;
}

.caption {
  color: var(--caption-text-color);
  background-color: var(--caption-background-color);
  vertical-align: 0em;
  padding-left: calc(0.7em - 0.5ex);
  padding-right: calc(0.7em - 0.5ex);
  padding-top: 0.025em;
  outline: calc(0.5em - 0.5ex + 0.05em) solid var(--caption-background-color);
  outline-offset: -0.5px;
  box-decoration-break: clone;
  -webkit-box-decoration-break: clone;
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes fadeOut {
  from { opacity: 1; }
  to { opacity: 0; }
}

.fade-in {
  animation: fadeIn 0.5s forwards;
}

.fade-out {
  animation: fadeOut 0.5s forwards;
} */

.main__app {
  height: 100vh !important;
  height: 100svh !important;
}