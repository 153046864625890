.MuiColorInput-TextField > * {
    border: none;
    outline: none;
}

#RunMeetingHeader {
    overflow: hidden;
    transition: ease-in-out 0.7s;
    right: 0;
}

#RunMeetingHeader.hide {
    /* width: 50px; */
    opacity: 0;
}

.header__stack {
    opacity: 0;
    animation: slowload 0.5s ease-in 0.5s forwards;
}

@keyframes slowload {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
    
}

#RunMeetingHeader * {
    white-space: nowrap;
}

/* #RunMeetingHeader.hide > *:not(#expand__icon) {
    display: none;
} */

#expand__icon {
    cursor: pointer;
}

/* #expand__icon {
    transition: ease-in-out 0.5s;
    opacity: 0;
}

#expand__icon.show {
    opacity: 1;
} */

button#hide-header-btn:hover {
    background-color: rgba(255, 255, 255, 0.08);
}